import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { Button, Container, Label, Loader, NumberInput } from '../../components/design-system'
import { Media } from '../../components/token/media'
import { waitSignerEthereum } from '../../libs/crypto/crypto'
import { getToken } from '../admin/manage-tokens/tokens'
import { ethereumStore, getSigner } from '../../state/crypto/ethereumStore'
import { tezosStore } from '../../state/crypto/tezosStore'
import { notificationStore } from '../../state/global/notificationStore'
import { cleanHash, getVCAMetadata } from '../../utils/crypto'
import { TokenSaleInfoBox, TokenExternalLinks } from './saleinfo'
import { getDateTime } from '../../utils/time'
import { getProvider } from '../../state/crypto/ethereumStore'

import styles from './styles.module.scss'
import { ethers } from 'ethers'
import { isMobileDevice, mobileDeepLink } from '../../libs/crypto/ethereum'

import { findEthArtistName } from '../../components/token/token'
import { ArtBlocksViewer } from './artblocks-display'
import { ARTBLOCKS_FLEX_CONTRACT_ADDRESS } from '../../constants'

const VCACustomFixedPrice = require('../../constants/vcacustomfp-abi.json')
const signatures = require('../../constants/signatures.json')


export function getSignature(key: string) {
    if (signatures[key] !== null) {
        return signatures[key]
    } else {
        return null
    }
}

export const PrivateTokenPage = () => {
    const notifications = notificationStore()
    const ethereumState = ethereumStore()

    const navigate = useNavigate()
    const [saleType, setSaleType] = useState<string>("")
    const [saleStart, setSaleStart] = useState<string>("")

    const [loading, setLoading] = useState(true)
    const [broken, setBroken] = useState(false)

    const [metadata, setMetadata] = useState<any>({})

    let timeout: any = useRef()

    const network: "ethereum" | "tezos" = window.location.pathname.split("/")[2] || "ethereum" as any
    const contract = window.location.pathname.split("/")[3]
    const id = parseInt(window.location.pathname.split("/")[4])
    
    const fetchVCAMetadata = async () => {
        try {
            const structuredMetadata = await getVCAMetadata({
                contract,
                id,
                custom: true
            })

            setMetadata(structuredMetadata)
            setLoading(false)
        } catch (error) {
            setBroken(true)
            console.log(error)
        }
    }

    useEffect(() => {
        if (!broken) return

        const timeout = setTimeout(() => {
            navigate("/", { replace: true })
        }, 1500)

        return () => clearTimeout(timeout)
    }, [broken])

    // Grabs token info from DB on mount
    useEffect(() => {

        // Check if Artblocks token 
        if (contract !== ARTBLOCKS_FLEX_CONTRACT_ADDRESS) {
            const getTokenFromDB = async () => {
                const data: any = await getToken(contract, network, id)
                setSaleType(data.tokens[0].saleType)
                setSaleStart(data.tokens[0].saleStart)

                if(data.tokens[0].saleType.toLowerCase().includes('vca-custom-fp') && network=="ethereum") {
                    fetchVCAMetadata()
                } 
                
                else {
                    setBroken(true)
                }
        
                return () => {
                    clearTimeout(timeout)
                }
            }

            getTokenFromDB();
        }

        else {
            setLoading(false)
        }

    }, [])

    
    const handleSubmitMint = async (contract: string, dropId: number, price: number) => {
        const notifId = notifications.addNotification({
            message: "Minting...",
            status: "pending",
        })

        let signature = getSignature(ethereumState.address+'_'+contract) || null

        // Get signature
        if (signature == null) {
            
            notifications.setNotificationMessage({
                id: notifId,
                message: "Sorry, you're not on the allow list"
            })

            notifications.reject(notifId)
        }


        else if (window.ethereum?.isMetaMask) {

            try {
                const signer = getSigner()
                const provider = getProvider()


                if (!signer) {
                    await waitSignerEthereum(window)
                }

                let overrides = {
                    value: price
                }

                const VCAFixedPriceContract = new ethers.Contract(contract, VCACustomFixedPrice.abi, signer)
                const tx = await VCAFixedPriceContract.alMint(dropId, signature, overrides)

                await provider.waitForTransaction(tx.hash);

                // Get the transaction receipt
                const receipt = await provider.getTransactionReceipt(tx.hash);

                // Check the status of the transaction
                if (receipt.status === 1) {
                    notifications.setNotificationMessage({
                    id: notifId,
                    message: "Token succesfully minted"
                    })
            
                    notifications.resolve(notifId)                   

                } else {
                    // Catch any errors
                    notifications.setNotificationMessage({
                        id: notifId,
                        message: "Error minting token"
                    })

                    notifications.reject(notifId)
                }
            } catch (error) {
            
                console.log(error)

                notifications.setNotificationMessage({
                    id: notifId,
                    message: "Error minting token"
                })

                notifications.reject(notifId)
            }
        } 

        else if (isMobileDevice()){
            mobileDeepLink(window.location.pathname)
        } 

        else {
            console.log("No Metamask wallet detected")

            notifications.setNotificationMessage({
                id: notifId,
                message: "Error minting token"
            })

            notifications.reject(notifId)
        }
    }
    

    if (loading) {
        return <Loader />
    }

    if (broken) {
        return (
            <Container>
                <div className={styles.container}>
                    <div>
                        This token doesn't seem to exist.
                    </div>
                    <Label>Bringing you home</Label>
                </div>
            </Container>
        )
    }

    if (contract == ARTBLOCKS_FLEX_CONTRACT_ADDRESS) {
        return (
            <ArtBlocksViewer projectId={id} setBroken={setBroken} />
        )
    } else {

    return (
        metadata && Object.keys(metadata).length == 0 ? (
            <Loader />
        ) : (
        <Container>
            <div className={styles.token__container}>
                <div className={styles.media__container}>
                    {metadata && Object.keys(metadata).length !== 0 ? (
                        <Media
                            mediaType={metadata?.metadata?.raw?.contentType?.split('/')[0] || metadata?.content?.mimeType?.split('/')[0] || 'image' }
                            cid={cleanHash(metadata?.metadata?.raw?.contentURI) || cleanHash(metadata?.metadata?.contentUri) || cleanHash(metadata?.metadata?.raw?.image) || "" }
                            placeholderCid={cleanHash(metadata?.metadata?.raw?.contentURI) || cleanHash(metadata?.metadata?.contentUri) || cleanHash(metadata?.metadata?.raw?.image) || ""}
                            displayCid={metadata?.metadata?.raw?.contentType?.split('/')[0] === 'audio' ? cleanHash(metadata?.media?.image?.uri) : ''}
                            className={styles.event__cover}
                            alt="Token Cover"

                            fill
                        />
                    ) : (
                        <div className={styles.broken}>
                            Error Loading Metadata
                            <Label>Check Token Info</Label>
                        </div>
                    )}
                </div>



                <div className={styles.info__container}>
                    <h1 className={styles.info__title}>
                        { metadata?.metadata?.name || "No Title Found" }
                    </h1>

                    <Label className={styles.creator}>
                        { "Created by " + metadata?.nft?.minted?.address && findEthArtistName(metadata?.nft?.minted?.address) }
                    </Label>

                    <div className={styles.info__description}>
                        { metadata?.metadata?.description || "No Description Found" }
                    </div>

                    <br />
                    <br />
                    
            {/** If token is a VCA Fixed Sale (ETH) */}
            { saleType == "vca-custom-fp" &&
              metadata.nft.drop.balance > 0 ? (
                <>
                <div className={styles.auction__container}>
                  <TokenSaleInfoBox
                    network="ethereum"
                    supply={metadata.nft.drop.supply}
                    balance={metadata.nft.drop.balance}
                    price={metadata.nft.drop.alPrice}
                    royalties={metadata.nft.drop.royalties/100}
                  />                        
                  <div>
                    <div className={styles.bid__container}>                           
                      <Button 
                        style={{ width: "100%" }}
                        className={styles.swap__submit}
                        onClick={() => handleSubmitMint(contract, id, metadata.nft.drop.alPrice)}
                      >
                      Mint for {metadata.nft.drop.alPrice/1e18}&nbsp;ETH
                      </Button>
                    </div>
                  </div>
                </div>
                <br/>
                  <TokenExternalLinks
                    network="ethereum"
                    contract={contract}
                    id={id}
                    ipfsImage={metadata?.metadata?.raw?.contentURI}
                    balance={metadata.nft.drop.balance}
                  />
                </>
            ):(
              <>
                <TokenExternalLinks
                  network="ethereum"
                  contract={contract}
                  id={id}
                  ipfsImage={metadata?.metadata?.raw?.contentURI}
                  balance={metadata.nft.drop.balance}
                />
                <br/>
                <p className={styles.sold_out}>Sold out</p>
              </> 
            )}
            </div>
          </div>
        </Container>
        )
    )
    }
}