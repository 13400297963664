import React from 'react';
import styles from './styles.module.scss'
import { Button, ButtonLink, Countdown, Label, LazyLoad } from '../design-system'
import { useEffect, useState } from 'react'
import { ethers } from "ethers"
import { getSigner } from "../../state/crypto/ethereumStore"
import { waitSignerEthereum } from '../../libs/crypto/crypto'
import { getAbi, getAbProjectInfo } from "../../utils/crypto"
import { findEthArtistName } from '../../components/token/token'

export const ArtblockProject = ({
    id,
    title,
    projectId,
    mintPrice,
    artistAddress,
    contract,
    to
} : {
    id: number;
    title: string;
    projectId: any;
    mintPrice: string;
    artistAddress: string;
    contract: any;
    to: string;
}) => {

    const [availability, setAvailability] = useState<number>(0)
    const [maxTokens, setMaxTokens] = useState<number>(0)

    useEffect(() => {
            const getArtblocksProjectInfo = async () => {
                try {
                    const projectDetails: any = await getAbProjectInfo(projectId)
                    setMaxTokens(projectDetails.maxInvocations.toNumber())
                    setAvailability(projectDetails.maxInvocations-projectDetails.invocations)
                } catch (error) {
                    console.error(error)
                }
            }
            getArtblocksProjectInfo()
    }, [])

    return (
        <a href={to} key={id}>
            <div className={styles.token} onClick={() => null}>
                <div className={styles.edit__container}>
                    <img
                        //src="/token_covers/KT1AAxomZ6cxN5zYj2efG1nQyM1ggab4e216_1.png"
                        alt="artblockstoken"
                        className={styles.event__cover}
                        src={("/token_covers/artblocks_" + contract + "_" + projectId  + '.png')}
                    />
                    <div className={styles.artist__name_event_view}>
                        {artistAddress ? findEthArtistName(artistAddress) : "No Creator Found"}
                    </div>
                    <Label>
                        <div className={styles.label__truncated}>{title || 'No Name Found'}</div>
                    </Label>
                    
                    <div className={styles.auction__history}>
                        <div className={styles.info__container}>
                            <div>
                                <Label>Edition of {maxTokens} - {availability} left</Label>

                                <div className={styles.event__title} style={{ paddingTop: 0 }}>
                                    { (mintPrice + " ETH") }
                                </div>
                            </div>

                            <ButtonLink to={to} className={styles.bid__button}>
                                Mint
                            </ButtonLink> 
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
}
