import { useEffect, useState, useRef } from 'react'

import { Link } from 'react-router-dom'
import { IPFS_GATEWAY } from '../../constants'
import { Button, ButtonLink, Countdown, Label, LazyLoad } from '../design-system'
import { shorten, getMetadata, cleanHash, getHistory, getSwaps } from '../../utils/crypto'
import styles from './styles.module.scss'
import { Media, MediaTypeEnum } from './media'

import { motion } from 'framer-motion'
import { getDateTime } from '../../utils/time'
import { SaleInfoPreview } from './previewinfo'
import { ethers } from 'ethers'
import { getToken } from "../../pages/admin/manage-tokens/tokens"

import {GetArtistInfos} from "./token-infos/getartistinfos"
import classNames from 'classnames'

const xtzArtists = require('../../constants/xtz-artists.json')
const ethArtists = require('../../constants/eth-artists.json')

export function findXTZArtistName(address: string) {
    let found;
  
    for (let i = 0; i < xtzArtists.length; i++) {
        if (xtzArtists[i].address === address) {
            found = xtzArtists[i]
        }
    }
  
    if (found) {
        return found.name
    }
    else {
        return shorten(address, 4)
    }
  }

export function findEthArtistName(address: string) {
    // console.log(address)
    let found;
    // let found = tmpNetwork.find(el => el.address === address)
    for (let i = 0; i < ethArtists.length; i++) {
        if (ethArtists[i].address === address) {
            found = ethArtists[i]
        }
    }
    // console.log(found)
    if (found) {
        return found.name
    }
    else {
        return shorten(address, 4)
    }
}

// Get mimetype of a URL resource
export const getMimeType = (url: string) => {
    let mimeType = null
    var xhr = new XMLHttpRequest();

    return new Promise((resolve, reject) => {

        xhr.open('HEAD', url, true);

        xhr.onload = function() {
            var contentType = xhr.getResponseHeader('Content-Type');
            resolve(contentType);
        };

        xhr.send();
    })
}

export const Token = ({
    network,
    contract,
    id,
    children,
    additionalInfo,
    externalUrl,
    saleType,
    saleStart,
    saleEnd,
    to = "",
    ...props
}: {
    network: "ethereum" | "tezos";
    contract: string;
    id: number;
    children?: React.ReactNode;
    additionalInfo?: string;
    externalUrl: any;
    saleType?: string;
    saleStart?: string;
    saleEnd?: string;
    to?: string;
    [key: string]: any;
}) => {
    const [loading, setLoading] = useState(true)
    //const [metadata, setMetadata] = useState<any>({})

    return (
        <div className={styles.token} {...props} onClick={() => null}>
            <div className={styles.edit__container}>
                <Link to={to} state={{ additionalInfo }}>
                    <img
                        alt="token"
                        className={styles.event__cover}
                        src={"/token_covers/" + contract + "_" + id  + '.png'}
                    />
                </Link>
            </div>
            <div className={styles.label__container}>
                <Label>{shorten(contract, 4)} | { id > 99999 ? `#${shorten(id.toString(), 3)}` : `#${id}`}</Label>
                <Label>{network}</Label>
            </div>

            <GetArtistInfos 
                network={network}
                contract={contract}
                id={id}
                externalUrl={externalUrl}
                saleType={saleType}
                saleStart={saleStart}
                saleEnd={saleEnd}
                key={contract + id.toString()}
            />
        </div>
    )
}

export const MockToken = ({
    artifact,
    display,
    mediaType,
    network,
    creator,
    title
}: {
    artifact: File,
    display: File,
    mediaType: any,
    network: "ethereum" | "tezos",
    creator: string,
    title: string
}) => {
    const [loading, setLoading] = useState(true)
    const [artifactURL, setArtifactURL] = useState<any>()
    const [displayURL, setDisplayURL] = useState<any>()

    useEffect(() => {
        if (artifact) {
            var reader = new FileReader()
            reader.readAsDataURL(artifact)

            reader.onload = () => {
                setArtifactURL(reader.result)
            }

            setLoading(false)
        }

        if (display) {
            const reader = new FileReader()
            reader.readAsDataURL(display)

            reader.onload = () => {
                setDisplayURL(reader.result)
            }
        }
    }, [, artifact, display])

    return (
        <div className={styles.token}>
            <div className={styles.edit__container}>
                {!loading ? (
                    <Media
                        mediaType={mediaType}
                        cid={artifactURL}
                        placeholderCid={displayURL}
                        className={styles.event__cover}
                        alt="Token Cover"

                        mock
                    />
                ) : (
                    <div className={styles.placeholder}>
                        <div className={styles.loader__container}>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, rotate: [0, 360] }}
                                exit={{ opacity: 0 }}

                                transition={{
                                    opacity: { loop: 0, delay: 1.5 },
                                    duration: 0.7,
                                    ease: "easeInOut",
                                    loop: Infinity,
                                    delay: 0.5,
                                }}

                                className={styles.loader}
                            />
                        </div>
                    </div>
                )}
            </div>
            <img src={artifactURL} className={styles.mintpreview}/>

            <div className={styles.label__container}>
                <Label>{network === "tezos" ? "tz1PREVIEW" : "0xPREVIEW"} | #PREVIEW</Label>
                <Label>{network}</Label>
            </div>

        <div className={styles.event__title}>                
            {shorten(creator, 4)}
        </div>
        
        <Label>
            {title || 'No Name Found'}
        </Label>
        </div>
    )
}