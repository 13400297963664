import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { fetchGraphcms, gql } from "../../../libs/graphcms"
import { tezosStore } from "../../../state/crypto/tezosStore"
import { PageWrapper, Container, DropdownInput, Input, Label, Modal, Button, NumberInput, CheckBoxInput, Loader } from "../../../components/design-system"
import { Masonry } from "@mui/lab"
import { IPFS_GATEWAY } from '../../../constants'
import { IEvent } from '../manage-events'

import { OpKind } from '@taquito/taquito'

import styles from './styles.module.scss'
import { getMetadata, shorten } from "../../../utils/crypto"
import { waitSignerEthereum, waitSignerTezos } from "../../../libs/crypto/crypto"
import { notificationStore } from "../../../state/global/notificationStore"
import { Token } from "../../../components/token/token"
import { addToken, publishToken } from '../manage-tokens/tokens'
import { addSwap } from '../../creator/swap/index'
import { findXTZArtistName, findEthArtistName } from '../../../components/token/token'

export interface ISwap {
    id: string;
    contract: string;
    tokenid: number;
    artifact_uri: string;
    amount: number;
    price: number;
    active: boolean;
    creator: string;
    royalties: number;
    event: any;
}
export const getSwaps = async (set: any, loading: any) => {
   //query hygraph
    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: gql.query.querySwaps
    }).then((response) => {
        const all_swaps = response.swaps
        set(all_swaps)
        //console.log(all_swaps)
        loading(false)
    }).catch((error) => {
        console.log(error)
    })
}

// Retrieve upcoming events
export const getEvents = async (set: any) => {
    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: gql.query.queryEvents
    }).then((response) => {
        const allEvents = response.events
        set(allEvents)
    }).catch((error) => {
        console.log(error)
    })
}

const removeSwap = async (id: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: gql.mutation.removeSwap,
                variables: {
                    id
                }
            })

            resolve(res)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export const ManageSwaps = () => {
    const location = useLocation()
    const notifications = notificationStore()

    const [swaps, setSwaps] = useState([])

    const tezosState = tezosStore()

    const [network, setNetwork] = useState("tezos")
    const [contract, setContract] = useState("")
    const [swapId, setSwapId] = useState("")
    const [tokenId, setTokenId] = useState(0)
    const [tokenPrice, setTokenPrice] = useState(0)
    const [tokenAmount, setTokenAmount] = useState(0)
    const [tokenCreator, setTokenCreator] = useState("")
    const [tokenDisplayURI, setTokenDisplayURI] = useState("")
    const [tokenRoyalties, setTokenRoyalties] = useState(0)
    const [eventId, setEventId] = useState("")
    const [events, setEvents] = useState<IEvent[]>([])
    const [selectedEvent, setSelectedEvent] = useState<any>([])
    const [selectedEventName, setSelectedEventName] = useState("")
    const [swapArray, setSwapArray] = useState(0)
    
    const [publishEventVAR, setPublishEventVAR] = useState<any[]>([])
    
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getSwaps(setSwaps, setLoading)
        getEvents(setEvents)
    }, [])

    // Retrieve selected event ID
    const getEventDetails = async (name: string, setEventId: any) => {
        
        await fetchGraphcms({
            key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
            query: gql.query.queryEventByName,
            variables: {
                name,
            }
        }).then(async (response: any) => {
            const event = response.events?.[0]
            let eventId = event?.id

            setEventId(eventId);
            setSelectedEvent(event)

        }).catch((error: any) => {
            console.log(error)
        })
    }
    const createSwaps = async () => {
        swapToken(publishEventVAR) 
    }
    const handleCheckboxChange = async (tokenId: any,tokenPrice: any,tokenAmount: any,contract: any,royalties: any,curator: any,creator: any,id: any) => {
        setPublishEventVAR({ ...publishEventVAR, [swapArray]: {
            tokenID: tokenId,
            tokenContract: contract,
            tokenPrice: tokenPrice,
            tokenAmount: tokenAmount,
            royalties: royalties,
            swapId: id,
            creator: creator,
            curator: curator
        } });
        setSwapArray(swapArray + 1);
    }
    const updateSwap = async (swapId: any) => {
        return new Promise<string>(async (resolve, reject) => {
            const query = gql.mutation.updateActiveSwap
    
            try {
                const data = await fetchGraphcms({
                    key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                    query: query,
                    variables: {
                        id: swapId,
                        active: true
                    }
                })
                resolve(swapId)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })

    }

    const swapToken = async (swaps: any) => {
        const notifID = notifications.addNotification({
            message: "Swap Token: ",
            status: "pending"            
        })
        // Interact with contract
        try {
            if (!tezosState?.signer) {
                await waitSignerTezos()
            }
            else{

                const Tezos = tezosState.provider

                const OBJKT_LIST_CONTRACT_ADDRESS = "KT1WvzYHCNBvDSdwafTHv7nJ1dWmZ8GCYuuC"
                const OBJKT_LIST_CONTRACT = await Tezos.contract.at(OBJKT_LIST_CONTRACT_ADDRESS)
                let list = [];
                for (let i = 0; i < Object.keys(swaps).length; i++) {
                    const TOKEN_CONTRACT = await Tezos.wallet.at(swaps[i].tokenContract);
                    list.push({
                        kind: OpKind.TRANSACTION,
                        ...TOKEN_CONTRACT.methods.update_operators([
                            {
                                add_operator: {
                                    owner: tezosState.address,
                                    operator: OBJKT_LIST_CONTRACT_ADDRESS,
                                    token_id: swaps[i].tokenID
                                }
                            }
                        ]).toTransferParams(),
                        storageLimit: 310,
                        source: tezosState.address
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...OBJKT_LIST_CONTRACT.methods.ask(
                            //params
                            swaps[i].tokenContract, // Token Contract Address
                            swaps[i].tokenID, // Token ID
                            "tez", // currency type 
                            "Unit", // currency address
                            swaps[i].tokenPrice*1000000, // reserve price in  micro tez
                            swaps[i].tokenAmount,
                            [
                                {
                                    amount: swaps[i].royalties, // Royalties to Token Creator - rest goes to vca (wallet that swapped token)
                                    recipient: swaps[i].creator
                                }
                                /*,
                                {
                                    amount: selectedEvent.curatorFee, // Royalties to Token Creator - rest goes to vca (wallet that swapped token)
                                    recipient: (swaps[i].curator * 100)-250,
                                }
                                ,
                                {
                                    amount: 2930, // 30% to VCA
                                    recipient: tezosState.address
                                },
                                {       // Royalty Split
                                    amount: 6820, // 70% to Token Creator
                                    recipient: tokenCreator
                                }*/
                            ]
                        ).toTransferParams(),
                        storageLimit: 310,
                        source: tezosState.address
                    })
        
                }

                let batch = Tezos.wallet.batch(list)
                let confirm = await batch.send()
                
                notifications.setNotificationMessage({
                    id: notifID,
                    message: "Waiting on Confirmations"
                })
                
                const tx = await confirm.confirmation(2)

                //console.log(confirm)

                notifications.setNotificationMessage({
                    id: notifID,
                    message: "Swap Created"
                })
                for (let i = 0; i < Object.keys(publishEventVAR).length; i++) {
                    await updateSwap(publishEventVAR[i].id)
                }
                notifications.setNotificationMessage({
                    id: notifID,
                    message: 'Swap Token Complete',
                })
    
                notifications.resolve(notifID)
                //return confirm;
            }
        } catch (error) {
            console.error(error)
            notifications.setNotificationMessage({
                id: notifID,
                message: "Swapping Failed",
            })

            notifications.reject(notifID)
        }
    }
    if (loading) {
        return (
            <Container>
                <Loader />
            </Container>
        )
    }
    return (
        <Container>
            <Label>Select Event</Label>

            <DropdownInput
                list={(events.map((event: IEvent) => { return event.name }))}
                value={selectedEventName}
                onChange={(event) => {
                    getEventDetails(event, setEventId).then(() => {
                        setSelectedEventName(event)
                    })
                }}
                
            >
            </DropdownInput>
            {selectedEvent != 0 &&
            <>
            <br/>
            <h1 style={{ marginBottom: '1em' }}>Selected Event: {selectedEvent.name}</h1>
            <table className={styles.user__table}>
                <thead>
                    <tr>
                        <th>tokenid</th>
                        <th>price</th>
                        <th>amount</th>
                        <th>creator</th>
                        <th>address</th>
                        <th>select to swap</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedEvent.swaps.map((swap: any) => (
                        <>
                            {swap.active ? (
                                null
                            ):(
                                <tr key={swap.id}>
                                    <td style={{ padding: '0 2rem' }}>{swap.tokenid}</td>  
                                    <td style={{ padding: '0 2rem' }}>{swap.price}</td> 
                                    <td style={{ padding: '0 2rem' }}>{swap.amount}</td>
                                    <td style={{ padding: '0 2rem' }}>{findXTZArtistName(swap.creator)}</td>
                                    <td style={{ padding: '0 2rem' }}>{swap.creator}</td>
                                    <td style={{ padding: '0 2rem' }}>
                                        <CheckBoxInput
                                            contract={swap.contract}
                                            onChange={(e) => handleCheckboxChange(
                                                swap.tokenid,
                                                swap.price,
                                                swap.amount,
                                                swap.contract,
                                                swap.royalties,
                                                selectedEvent.account.tezos,
                                                swap.creator,
                                                swap.id
                                            )}
                                        >
                                        </CheckBoxInput>
                                    </td>
                                </tr>  
                            )}
                            </>                                 
                        ))}
                </tbody>
            </table>
            <br/>
            <Button
                onClick={createSwaps}
            > Confirm Swaps
            </Button>
            </>
            }
        </Container>
    )
}
