import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { connectMetamaskBackground } from '../../libs/crypto/ethereum'
import { connectBeaconBackground } from '../../libs/crypto/tezos'
import { ethereumStore } from '../../state/crypto/ethereumStore'

import { Logo } from "./components/profile/logo"
import { Profile } from "./components/profile/profile"

import styles from './styles.module.scss'

export const Header = () => {
    useEffect(() => {
        connectMetamaskBackground(window)
        connectBeaconBackground()
    }, [])

    return (
        <div className={styles.header}>
            <div className={styles.header__container}>
                <Link className={styles.logo__container} to="/">
                    <Logo />
                </Link>
                <Link className={styles.events__link__container} to="/exhibitions">
                    Exhibitions
                </Link>
               { /* <Link className={styles.events__link__container} to="https://app.manifold.xyz/c/Ensemble">
                    Live
                </Link> */}
                &nbsp;
                <a href="https://app.manifold.xyz/c/memo-waves2-f001" style={{ paddingLeft: "2rem" }}  target="_blank" rel="noopener noreferrer" className={styles.dummy__buy__button}>
                    Live
                </a>
            </div>
            <Profile />
        </div>
    )
}
