import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { getTail } from "../../../../routers/global-router"
import { fetchGraphcms, gql } from "../../../../libs/graphcms"
import { Container, DropdownInput, Input, Label, Modal, Button, NumberInput, Loader } from "../../../../components/design-system"
import { LazyLoad } from "../../../../components/design-system/lazy/lazy"
import { IPFS_GATEWAY } from "../../../../constants"
import { Link } from "react-router-dom"
import { Masonry } from "@mui/lab"
import { DummyToken } from "../../../../components/token/dummytoken"

import styles from './styles.module.scss'
import { getMetadata, shorten } from "../../../../utils/crypto"
import { notificationStore } from "../../../../state/global/notificationStore"
import { Token } from "../../../../components/token/token"

export interface IToken {
    id: string;
    cryptoNetwork: "ethereum" | "tezos";
    contract: string;
    tokenId: number;
    metadata?: any;
    externalUrl?: string;
    swapdata?: any;
    additionalInfo?: string;
    saleType?: string;
    saleStart?: string;
    saleEnd?: string;
}

interface IEvent {
    slug: string;
    name: string;
    network: "";
    id: string;
    cover: string;
    curatorFee: number;
    endDate: string;
    startDate: string;
    tokens: IToken[];
}

export const getEvent = async (id: string, set: any, set2: any, loading: any) => {
    await fetchGraphcms({
        key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
        query: gql.query.queryEvent,
        variables: {
            id,
        }
    }).then(async (response) => {
        const event = response.events?.[0]
        let tokens = event?.tokens

        if (tokens && tokens.length > 0) {

            for (let i = 0; i < tokens.length; i++) {
                const token = tokens[i]
                    if (!token.externalUrl) {
                    try {
                        const metadata = await getMetadata({
                            network: token.cryptoNetwork,
                            contract: token.contract,
                            id: token.tokenId,
                        })
            
                        tokens[i].metadata = metadata || null
                    } catch (error) {
                        console.log(error)
                    }
                }
            }

            set(event)
            set2(tokens)
        }

        loading(false)
    }).catch((error) => {
        console.log(error)
    })
}

export const addToken = async ({ token, type, eventId }: { token: IToken, type: "add" | "edit", eventId: string }) => {
    return new Promise<string>(async (resolve, reject) => {
        const query = type === "add" ? gql.mutation.addToken : gql.mutation.editToken

        try {
            const data = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: query,
                variables: {
                    id: token?.id || "",
                    cryptoNetwork: token.cryptoNetwork,
                    contract: token.contract,
                    tokenId: token.tokenId,
                    saleType: token.saleType,
                    saleStart: token.saleStart,
                    saleEnd: token.saleEnd,
                    eventId,
                }
            })

            const id: string = type === 'add' ? data.createToken.id : token.id
            resolve(id)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export const publishToken = async (id: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: gql.mutation.publishToken,
                variables: {
                    id
                }
            })

            resolve(res)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}


export const getToken = async (contract: string, cryptoNetwork: string, tokenId: number) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: gql.query.getToken,
                variables: {
                    contract: contract,
                    cryptoNetwork: cryptoNetwork,
                    tokenId: tokenId
                }
            })
            resolve(res)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

const removeToken = async (id: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetchGraphcms({
                key: process.env.REACT_APP_GRAPHCMS_ADMIN_KEY,
                query: gql.mutation.removeToken,
                variables: {
                    id
                }
            })

            resolve(res)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export const Tokens = () => {
    const location = useLocation()
    const notifications = notificationStore()

    const [id, setId] = useState('')
    const [event, setEvent] = useState({} as IEvent)
    const [tokens, setTokens] = useState([])

    const [openAddToken, setOpenAddToken] = useState(false)
    const [openEditToken, setOpenEditToken] = useState("")

    const [network, setNetwork] = useState("ethereum")
    const [contract, setContract] = useState("")
    const [tokenId, setTokenId] = useState(0)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const id = getTail({ path: location.pathname, depth: 3 })

        setId(id)
        getEvent(id, setEvent, setTokens, setLoading)
    }, [])

    const handleAddToken = async (type: "add" | "edit") => {
        if (!contract) return

        const notifID = notifications.addNotification({
            message: type === 'add' ? 'Adding Token' : 'Editing Token',
            status: 'pending',
        })

        try {
            const res = await addToken({
                token: {
                    id: openEditToken || "",
                    tokenId,
                    cryptoNetwork: network as "ethereum" | "tezos",
                    contract
                },
                type,
                eventId: event.id
            })

            notifications.setNotificationMessage({
                id: notifID,
                message: 'Publishing Token',
            })

            await publishToken(res)

            notifications.setNotificationMessage({
                id: notifID,
                message: 'Published Token',
            })

            notifications.resolve(notifID)

            getEvent(id, setEvent, setTokens, setLoading).then(() => {
                setContract("")
                setTokenId(0)
                setNetwork("ethereum")

                setOpenAddToken(false)
                setOpenEditToken("")
            })
        } catch (error) {
            console.log(error)
            notifications.reject(notifID)
        }
    }

    if (loading) {
        return (
            <Container>
                <Loader />
            </Container>
        )
    }

    return (
        <Container>
            <h1 style={{ marginBottom: '1em' }}>Event: {event?.name}</h1>

            <Masonry columns={{ xs: 1, sm: 2, lg: 4 }}>
                {tokens.map((token: IToken) => {
                    return token.externalUrl ? 
                    (
                        <DummyToken id={token.tokenId} to={token.externalUrl} metadata={token.metadata} cryptoNetwork={token.cryptoNetwork} contract={token.contract}/> 
                    )
                    :
                    (
                    <Token
                        network={token.cryptoNetwork}
                        contract={token.contract}
                        id={token.tokenId}
                        key={token.contract + token.tokenId.toString()}
                        externalUrl={token.externalUrl}
                        onClick={() => {
                            setTokenId(token?.tokenId || 0)
                            setNetwork(token?.cryptoNetwork || "ethereum")
                            setContract(token?.contract || "")

                            setOpenEditToken(token.id)
                        }}
                    >
                        <div
                            onClick={async () => {
                                const notifID = notifications.addNotification({
                                    message: 'Removing Token',
                                    status: 'pending',
                                })

                                try {
                                    await removeToken(token.id as string)
                                    
                                    notifications.setNotificationMessage({
                                        id: notifID,
                                        message: 'Token Removed',
                                    })

                                    getEvent(id, setEvent, setTokens, setLoading)

                                    notifications.resolve(notifID)
                                } catch (error) {
                                    console.log(error)
                                    notifications.reject(notifID)
                                }
                            }}
                        >
                            <Label className={styles.remove} right>Remove</Label>
                        </div>
                    </Token>
                    )
                })}

                <div className={`${styles.token} ${styles.add__container}`} onClick={() => setOpenAddToken(true)}>
                    <div className={styles.edit__container} style={{ margin: '0 auto', padding: '2rem 0', width: 'fit-content' }}>
                        <svg className={styles.add__event} xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                            <path d="M22.5 38V25.5H10V22.5H22.5V10H25.5V22.5H38V25.5H25.5V38Z"/>
                        </svg>
                    </div>

                    <div className={styles.event__title}>Add token</div>
                </div>
            </Masonry>

            <Modal
                open={openAddToken || !!openEditToken}
                onClose={() => {
                    setNetwork("ethereum")
                    setTokenId(0)
                    setContract("")

                    setOpenAddToken(false)
                    setOpenEditToken("")
                }}
                title={openEditToken ? 'Edit event' : 'Add event'}
            >
                <Modal.Wrapper key="network">
                    <Label>Network</Label>

                    <DropdownInput value={network} onChange={(network) => setNetwork(network)} list={["ethereum", "tezos"]} />
                </Modal.Wrapper>

                <Modal.Wrapper key="contract">
                    <Label>Contract</Label>

                    <Input
                        value={contract}
                        onChange={(e) => setContract(e.target.value)}
                        placeholder={`Contract Address (eg. ${network === 'ethereum' ? '0x...' : 'KT1...'})`}
                    />
                </Modal.Wrapper>

                <Modal.Wrapper key="tokenId">
                <Label>Token ID</Label>

                <NumberInput
                    value={tokenId}
                    onChange={(e) => setTokenId(parseInt(e.target.value))}
                    placeholder={`Token ID (eg. 1, 2, 3...)`}
                />
                </Modal.Wrapper>

                <Modal.Spacer />

                <Modal.Wrapper>
                    <Button
                        // disabled={!ready} TODO: ADD
                        onClick={async () => {
                            handleAddToken(openEditToken ? 'edit' : 'add')
                        }}
                    >
                        {/* {ready ? ( */}
                            {openEditToken ? 'Update' : 'Add'}
                        {/* ) : ( */}
                            {/* !timeReady ? 'Start time must be smaller than end time' : 'Please fill out all fields' */}
                        {/* )} */}
                    </Button>
                </Modal.Wrapper>
            </Modal>

        </Container>
    )
}
