import styles from './styles.module.scss'
import { ARTBLOCKS_IMAGE_PREVIEW, ARTBLOCKS_GENERATOR_ADDRESS, ARTBLOCKS_TOKEN_ADDRESS, ARTBLOCKS_FLEX_CONTRACT_ADDRESS } from '../../constants'
import { Label, LazyLoad } from '../design-system'
import { Link } from 'react-router-dom';


export const ArtBlocksMintedPreview = ({
    tokenId, projectId,
}: {tokenId: number, projectId: number}) => {

    return (
        <div className={`${styles.token} ${styles.previously__minted}`}>
            <div className={styles.edit__container}>
                <Link to={`/token/ethereum/${ARTBLOCKS_FLEX_CONTRACT_ADDRESS}/${projectId.toString()}/${tokenId.toString()}`}>

                <LazyLoad
                    alt="Generative art preview"
                    className={styles.event__cover} style={{ height: "100%" }}
                    src={ARTBLOCKS_IMAGE_PREVIEW+projectId.toString()+tokenId.toString().padStart(6, "0")+'.png'}
                />
               </Link>
            </div>

            <div className={styles.label__container}>
                <Label>{`Token #${tokenId.toString()}`}</Label>
                <a href={ARTBLOCKS_GENERATOR_ADDRESS+projectId.toString()+tokenId.toString().padStart(6, "0")} target="_blank">
                    <Label>Live View</Label>
                </a>
            </div>
        </div>
    )

}