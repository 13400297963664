import { Home } from "../pages/home"
import { Admin } from "../pages/admin"
import { NotFound } from "../pages/404"

import type { IRoutes } from "../types/routes"
import { Wallet } from "../pages/wallet"
import { LogIn } from "../pages/log-in"
import { Event } from "../pages/event"
import { DummyEvent } from "../pages/event/dummyevent"
import { TokenPage } from "../pages/token"
import { PrivateTokenPage } from "../pages/token/private-token"
import { BundlePage } from "../pages/token/bundle"
import { Creator } from "../pages/creator"
import { Clearance } from "../containers/clearance"

export const routes: IRoutes = {
    routerId: 'globalRouter',
    depth: 1,
    pages: [
        {
            path: '/',
            element: DummyEvent,
            animate: true,
        },
        {
            path: '/exhibitions',
            element: Home,
            animate: true,
        },
        {
            path: '/event/*',
            element: Event,
            animate: true,
        },
        {
            path: '/token/:network/:contract/:id/*',
            element: TokenPage,
            animate: true,
        },
        {
            // Path for AL mints
            path: '/private/:network/:contract/:id/*',
            element: PrivateTokenPage,
            animate: true,
        },
        {
            // Path for AL bundle mints
            path: '/private/bundle/:network/:contract/:id/*',
            element: () => (<BundlePage allowlist={true} />),
            animate: true,
        },
        {
            // Path for bundled token sales
            path: '/bundle/:network/:contract/:id',
            element: () => (<BundlePage allowlist={false} />),
            animate: true,
        },
        {
            path: '/admin/*',
            element: () => (
                <Clearance required={2}>
                    <Admin />
                </Clearance>
            ),
            animate: false,
        },
        {
            path: '/creator/*',
            element: () => (
                <Clearance required={1}>
                    <Creator />
                </Clearance>
            ),
            animate: false,
        },
        {
            path: '/wallets',
            element: Wallet,
            animate: true,
        },
        {
            path: '/log-in',
            element: LogIn,
            animate: true,
        },
        {
            path: '/404',
            element: NotFound,
            animate: true,
        }
    ]
}

